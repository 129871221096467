'use client';

import { useCallback, useEffect, useRef, useState } from 'react';
import useMagneticEffect from '@/hooks/useMagneticEffect';
import useScreen from '@/hooks/useScreenMedia';
import { motion, useSpring } from 'framer-motion';

const PADDING = 12;

export default function CustomCursor() {
  const { isMobile } = useScreen();
  const [isHovering, setIsHovering] = useState(false);
  const [hoverTarget, setHoverTarget] = useState<DOMRect | null>(null);

  // Use springs for smoother cursor movement
  const x = useSpring(0, { stiffness: 1000, damping: 50, duration: 1000 });
  const y = useSpring(0, { stiffness: 1000, damping: 50, duration: 1000 });

  // Initialize magnetic effect
  useMagneticEffect();

  const lastUpdateTime = useRef(0);
  const lastInteractionCheck = useRef(0);
  const lastHoverState = useRef<{ isHovering: boolean; target: DOMRect | null }>({
    isHovering: false,
    target: null,
  });

  const updateMousePosition = useCallback(
    (e: MouseEvent) => {
      if (isMobile) return;

      const now = Date.now();
      // Always update cursor position with minimal throttle
      if (now - lastUpdateTime.current > 16) {
        // ~60fps
        lastUpdateTime.current = now;
        x.set(e.clientX - PADDING / 2);
        y.set(e.clientY - PADDING / 2);
      }

      // Check interactive elements less frequently
      if (now - lastInteractionCheck.current > 100) {
        lastInteractionCheck.current = now;

        requestAnimationFrame(() => {
          const element = document.elementFromPoint(e.clientX, e.clientY);
          const interactive = element?.closest('[data-interactive]');
          const newState = {
            isHovering: !!interactive,
            target: interactive ? interactive.getBoundingClientRect() : null,
          };

          // Only update state if there's a change
          if (
            newState.isHovering !== lastHoverState.current.isHovering ||
            JSON.stringify(newState.target) !== JSON.stringify(lastHoverState.current.target)
          ) {
            lastHoverState.current = newState;
            setIsHovering(newState.isHovering);
            setHoverTarget(newState.target);
          }
        });
      }
    },
    [isMobile, x, y]
  );

  useEffect(() => {
    if (isMobile) return;

    const options = { passive: true };
    window.addEventListener('wheel', updateMousePosition, options);
    window.addEventListener('mousemove', updateMousePosition, options);

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
      window.removeEventListener('wheel', updateMousePosition);
    };
  }, [isMobile, updateMousePosition]);

  if (isMobile) return null;

  return (
    <motion.div
      className="bg-primary-content pointer-events-none fixed top-0 left-0 z-[100] size-4 rounded-full border-0 will-change-[width,height]"
      style={{
        x,
        y,
        ...(isHovering && {
          borderRadius: '4px',
          opacity: 0.1,
        }),
        ...(hoverTarget && {
          x: hoverTarget.x - PADDING / 2,
          y: hoverTarget.y - PADDING / 2,
          width: hoverTarget.width + PADDING,
          height: hoverTarget.height + PADDING,
        }),
      }}
    />
  );
}
