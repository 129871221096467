/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 src/components/PreLoader/bishop.glb 
*/

import { forwardRef } from 'react';
import { useGLTF } from '@react-three/drei';

const Bishop = forwardRef((props: any, ref: any) => {
  const { nodes, materials } = useGLTF('/bishop.glb');
  return (
    <group {...props} dispose={null} ref={ref}>
      <mesh
        geometry={(nodes.bishop as any).geometry}
        material={materials.Material}
        position={[-0.615, -0.066, -0.859]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.035}
      />
    </group>
  );
});

useGLTF.preload('/bishop.glb');

Bishop.displayName = 'Bishop';
export default Bishop;
