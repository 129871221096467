import { ReactNode, useMemo, useRef } from 'react';
import { motion, MotionValue, useScroll, useTransform } from 'framer-motion';

type AnimatedCharProps = {
  char: string;
  index: number;
  scrollYProgress: MotionValue<number>;
  stagger: number;
  className?: string;
};

const AnimatedChar = ({ char, index, scrollYProgress, stagger, className }: AnimatedCharProps) => {
  const opacity = useTransform(
    scrollYProgress,
    [0, 0.1 + index * stagger, 0.3 + index * stagger, 1],
    [0, 0, 1, 1]
  );

  const y = useTransform(
    scrollYProgress,
    [0, 0.1 + index * stagger, 0.3 + index * stagger, 1],
    [20, 20, 0, 0]
  );

  return (
    <motion.span
      className={className}
      style={{
        display: 'inline-block',
        opacity,
        y,
      }}
    >
      {char === ' ' ? '\u00A0' : char}
    </motion.span>
  );
};

const FloatText = ({
  children,
  containerClassName = '',
  className = '',
  stagger = 0.03,
}: {
  children: ReactNode;
  containerClassName?: string;
  className?: string;
  animationDuration?: number;
  stagger?: number;
}) => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start end', 'end start'],
  });

  const text = useMemo(() => {
    if (typeof children === 'string') {
      return children
        .split('')
        .map((char, index) => (
          <AnimatedChar
            className={className}
            key={index}
            char={char}
            index={index}
            scrollYProgress={scrollYProgress}
            stagger={stagger}
          />
        ));
    }
    return [];
  }, [children, className, scrollYProgress, stagger]);

  return (
    <motion.h2 ref={containerRef} className={`my-5 overflow-hidden ${containerClassName}`}>
      {text}
    </motion.h2>
  );
};

export default FloatText;
