import { AnchorHTMLAttributes, ReactNode } from 'react';

import css from './InlineLink.module.css';

export default function InlineLink({
  children,
  className,
  ...props
}: AnchorHTMLAttributes<HTMLAnchorElement> & { children: ReactNode }) {
  return (
    <a
      {...props}
      className={`${css.linkWrapper} group relative flex cursor-pointer items-center gap-2 ${className}`}
      data-interactive
    >
      <span>{children}</span>
      <svg
        viewBox="0 0 24 24"
        className="stroke-primary-content size-5 fill-none stroke-[3px] opacity-20 transition-opacity duration-300 ease-in-out group-hover:opacity-100"
      >
        <line
          x1="5"
          y1="12"
          x2="19"
          y2="12"
          className="translate-x-[10px] scale-x-0 transition-transform duration-300 ease-in-out group-hover:translate-x-0 group-hover:scale-x-100"
        />
        <polyline
          points="12 5 19 12 12 19"
          className="-translate-x-2 transition-transform duration-300 ease-in-out group-hover:translate-x-0"
        />
      </svg>
    </a>
  );
}
