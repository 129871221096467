'use client';

import { Copyright, Facebook, GitHub, LinkedIn } from '@mui/icons-material';
import { motion } from 'framer-motion';

export default function Footer() {
  const socialItemClassName =
    'hover:cursor-pointer will-change-transform text-lg flex will-change-transform' +
    ' items-center' +
    ' justify-center' +
    ' w-[32px] aspect-square rounded text-neutral-content';
  const itemAnimationProps = {
    whileHover: {
      scale: 1.1,
    },
  };
  return (
    <footer className="footer bg-secondary text-neutral-content">
      <div className="container flex flex-col items-start justify-between gap-2 py-4 sm:flex-row sm:items-center">
        <aside>
          <p>
            All right reserved <Copyright /> {new Date().getFullYear()}
          </p>
        </aside>
        <nav>
          <ul className="flex flex-row gap-4">
            <motion.li className={socialItemClassName} {...itemAnimationProps}>
              <a
                data-interactive
                target="_blank"
                href="https://github.com/huubinh49"
                rel="noopener noreferrer"
                aria-label="See github profile"
              >
                <GitHub />
              </a>
            </motion.li>
            <motion.li className={socialItemClassName} {...itemAnimationProps}>
              <a
                data-interactive
                target="_blank"
                href="https://www.facebook.com/huubinh49/"
                rel="noopener noreferrer"
                aria-label="See facebook profile"
              >
                <Facebook />
              </a>
            </motion.li>
            <motion.li className={socialItemClassName} {...itemAnimationProps}>
              <a
                data-interactive
                target="_blank"
                href="https://www.linkedin.com/in/huubinh49/"
                rel="noopener noreferrer"
                aria-label="See linkedIn profile"
              >
                <LinkedIn />
              </a>
            </motion.li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}
