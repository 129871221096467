import { useCallback, useEffect, useRef, useState } from 'react';

export default function useSectionObserver(lenis: any, threshold = 20) {
  const [activeSection, setActiveSection] = useState<string>('hero');
  const sectionsRef = useRef<Element[]>([]);
  const lastScrollTime = useRef(0);
  const lastActiveSection = useRef(activeSection);

  // Memoize scroll handler
  const handleScroll = useCallback(() => {
    const now = Date.now();
    // Throttle to once every 100ms
    if (now - lastScrollTime.current < 100) return;
    lastScrollTime.current = now;

    requestAnimationFrame(() => {
      const viewportHeight = window.innerHeight;
      let newActiveSection = lastActiveSection.current;

      // Use cached sections
      for (const section of sectionsRef.current) {
        const rect = section.getBoundingClientRect();
        if (viewportHeight * 0.6 >= rect.top && threshold <= rect.bottom) {
          newActiveSection = section.id;
          break; // Exit early once we find the active section
        }
      }

      // Only update state if section changed
      if (newActiveSection !== lastActiveSection.current) {
        lastActiveSection.current = newActiveSection;
        setActiveSection(newActiveSection);
      }
    });
  }, [threshold]);

  useEffect(() => {
    // Cache sections on mount and when threshold changes
    sectionsRef.current = Array.from(document.querySelectorAll('section[id]'));
    lenis?.on('scroll', handleScroll);

    return () => lenis?.off('scroll', handleScroll);
  }, [lenis, handleScroll]);

  return activeSection;
}
