'use client';

import { HTMLAttributes, useRef } from 'react';
import { useScroll } from 'framer-motion';

import FloatText from '../FloatText/FloatText';
import CategorySection from './CategorySection';

type IProjectSection = {
  categories: Array<any>;
};

export default function ProjectSection({
  categories,
  ...props
}: IProjectSection & HTMLAttributes<HTMLElement>) {
  const container = useRef(null);

  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start start', 'end end'],
  });

  return (
    <>
      <FloatText className="selected:scale-200 text-primary-content text-center text-5xl leading-tight font-bold tracking-tight lg:text-5xl">
        Side Projects
      </FloatText>
      <div className="h-24 w-full" />
      <section className="container mb-28" ref={container} {...props}>
        {categories.map((category, index) => (
          <CategorySection
            key={index}
            index={index}
            {...category}
            targetScale={1 - (categories.length - index - 1) * 0.05}
            progress={scrollYProgress}
            range={[index * 0.25, 1]}
          />
        ))}
      </section>
    </>
  );
}
