'use client';

import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import { useForm } from '@tanstack/react-form';
import { QueryClient, QueryClientProvider, useMutation } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import { z } from 'zod';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const messageSchema = z.object({
  message: z
    .string()
    .nonempty('You must type a non-empty string')
    .max(200, 'Message must be at most 200 characters'),
});

const queryClient = new QueryClient();
const recommendedQuestions = [
  'Introduce Binh to me?',
  "Tell me about Binh's skills.",
  'What projects have Binh worked on?',
];

const ChatBotComponent = ({ chatAction }: any) => {
  const [messages, setMessages] = useState<
    Array<{
      type: 'user' | 'bot';
      text: string;
    }>
  >([
    {
      type: 'bot',
      text: 'Hello, what can I tell you about myself!',
    },
  ]);
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const [isChatted, setIsChatted] = useState(false);

  const chat = useMutation({
    mutationFn: (message: string) => {
      return chatAction({ message })
        .then((answer: string) => {
          setMessages((prev) => [
            ...prev.slice(0, prev.length - 1),
            {
              type: 'bot',
              text: answer,
            },
          ]);
        })
        .catch((err: any) => {
          setMessages((prev) => [
            ...prev.slice(0, prev.length - 1),
            {
              type: 'bot',
              text: 'Something went wrong!, Could you please contact me through my [email](mailto:huubinh0409@gmail.com)?',
            },
          ]);
        });
    },
  });
  const form = useForm({
    defaultValues: {
      message: '',
    },
    validators: {
      onChange: messageSchema,
    },
    onSubmit: async ({
      value: { message },
    }: {
      value: {
        message: string;
      };
    }) => {
      const userMessage = message.trim();
      if (chat.isPending || !userMessage) {
        return;
      }
      form.reset();
      setIsChatted(true);
      setMessages((prev) => [
        ...prev,
        {
          type: 'user',
          text: userMessage,
        },
        {
          type: 'bot',
          text: 'Texting...',
        },
      ]);

      return chat.mutate(userMessage);
    },
  });

  const handleQuestionClick = (question: string) => {
    form.setFieldValue('message', question);
    form.handleSubmit();
  };

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    messageContainerRef.current?.scrollTo({
      top: messageContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [messages]);

  const handleKeydown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      form.handleSubmit();
    }
  };

  return (
    <>
      <div
        className={`${isOpen ? 'flex flex-col' : 'hidden'} fixed right-0 bottom-24 z-[60] w-screen overflow-hidden rounded-lg bg-slate-100 text-clip shadow-lg sm:right-10 sm:w-[360px]`}
      >
        <header className="bg-neutral h-[48px]">
          <h2 className="text-neutral-content p-2 text-center text-2xl">Agent</h2>
        </header>
        <div className="flex grow flex-col gap-8 p-4">
          <div
            data-lenis-prevent
            className="my-2 flex h-[200px] flex-col gap-4 overflow-y-auto"
            ref={messageContainerRef}
          >
            {messages.map((message, index) => (
              <div
                key={index}
                className={`bg-neutral max-w-[70%] rounded-md p-2 text-sm break-words focus:ring-0 ${message.type === 'bot' ? 'text-gray-300' : 'text-neutral-content self-end'} `}
              >
                <ReactMarkdown>{message.text}</ReactMarkdown>
              </div>
            ))}
          </div>
          {!isChatted && (
            <div className="mb-1 flex flex-wrap gap-2">
              {recommendedQuestions.map((question, index) => (
                <button
                  data-interactive
                  key={index}
                  className="text-primary-content bg-primary rounded-sm border border-blue-300 px-3 py-1"
                  onClick={() => handleQuestionClick(question)}
                >
                  {question}
                </button>
              ))}
            </div>
          )}

          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              form.handleSubmit();
            }}
          >
            <div className="relative h-24 w-full">
              <form.Field name="message">
                {(field: any) => (
                  <textarea
                    className={
                      'absolute top-0 left-0 m-0 size-full rounded-md bg-gray-100 p-3 text-gray-900 placeholder:text-sm placeholder:text-slate-300 focus:ring-2 focus:ring-offset-2 focus:outline-none'
                    }
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                      field.handleChange(e.target.value)
                    }
                    autoCapitalize="on"
                    spellCheck
                    autoCorrect="on"
                    required
                    placeholder="Everything you want to know about me..."
                    onKeyDown={handleKeydown}
                  />
                )}
              </form.Field>
              <div className="absolute top-1/2 right-1.5 -translate-y-1/2 p-2">
                {chat.isPending ? (
                  <LoadingSpinner />
                ) : (
                  <button data-interactive className="cursor-pointer" type="submit">
                    <SendSharpIcon
                      className="text-primary-content dark:text-secondary"
                      sx={{ fontSize: 24 }}
                    />
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      <motion.button
        data-interactive
        aria-label="Toggle chatbot"
        className="group bg-neutral fixed right-10 bottom-10 z-[60] size-10 rounded-full p-2 group-hover:scale-[1.1] hover:cursor-pointer"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {isOpen ? (
          <ExpandLessOutlinedIcon
            className="text-neutral-content"
            sx={{
              fontSize: 22,
            }}
          />
        ) : (
          <MessageOutlinedIcon
            className="text-neutral-content"
            sx={{
              fontSize: 16,
            }}
          />
        )}
      </motion.button>
    </>
  );
};

export default function ChatBot({
  chatAction,
}: {
  chatAction: (message: string) => Promise<string>;
}) {
  return (
    <QueryClientProvider client={queryClient}>
      <ChatBotComponent chatAction={chatAction} />
    </QueryClientProvider>
  );
}
