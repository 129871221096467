'use client';

import { HTMLAttributes, PropsWithChildren, Suspense, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { useGlobalStateStore } from '@/providers/globalState';
import { Html } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { motion } from 'framer-motion';

import useSectionObserver from '../../hooks/useActiveSection';
import { useLenisContext } from '../../providers/scroll/LenisWrapper';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const AvatarScene = dynamic(() => import('./AvatarScene'), { ssr: false });
const HeroBackground = dynamic(() => import('./HeroBackground'), { ssr: false });
const Spiral = dynamic(() => import('./Spiral'), { ssr: false });

const Hero = (props: PropsWithChildren<HTMLAttributes<HTMLElement>>) => {
  const { lenis } = useLenisContext();
  const loading = useGlobalStateStore((state: any) => state.loading);
  const section = useSectionObserver(lenis, 100);
  const activeSection = loading ? 'preloader' : section;

  const scene = useMemo(() => <Spiral />, []);

  const defaultStyle = {
    zIndex: -10,
    top: '50vh',
    left: '50vw',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 600,
    opacity: 0,
  };

  return (
    <section
      className={`relative h-[300vh] w-full overflow-x-clip bg-transparent text-clip sm:h-[300vh]`}
      {...props}
    >
      {/* Background && Avatar */}
      <div
        className={
          'sticky top-0 left-0 -z-10 h-[120vh] w-[120vw] translate-x-[-10vw] translate-y-[-10vh]'
        }
      >
        <HeroBackground />
      </div>
      <motion.div className="fixed top-0 left-0 -z-10 size-full overflow-hidden">
        {scene}
      </motion.div>
      <motion.div
        animate={activeSection}
        className="!pointer-events-none fixed z-40 aspect-[2/3] w-[300px] md:w-[500px]"
        style={{
          top: '50vh',
          left: '50vw',
          transform: 'translate(-50%, -50%)',
          width: '100vw',
          height: '100vh',
        }}
        variants={{
          hero: {
            top: '50vh',
            left: '50vw',
            transform: 'translate(-50%, -50%)',
            width: '100vw',
            height: '100vh',
            opacity: 1,
          },
          skills: {
            top: '50vh',
            left: '50vw',
            transform: 'translate(-50%, -50%)',
            width: '100vw',
            height: '100vh',
            zIndex: -10,
            opacity: 1,
          },
          projects: defaultStyle,
          blog: defaultStyle,
          contact: defaultStyle,
        }}
      >
        <Canvas className="!pointer-events-none w-full" shadows>
          <Suspense
            fallback={
              <Html>
                <LoadingSpinner />
              </Html>
            }
          >
            <AvatarScene activeSection={activeSection} />
          </Suspense>
        </Canvas>
      </motion.div>
    </section>
  );
};

export default Hero;
