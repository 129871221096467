'use client';

import { useCallback, useEffect, useRef } from 'react';

export default function useMagneticEffect() {
  const elementsMap = useRef(new Map<HTMLElement, { originalTransform: string }>());

  // Store original transform when element is first encountered
  const getElementData = (element: HTMLElement) => {
    if (!elementsMap.current.has(element)) {
      const style = window.getComputedStyle(element);
      elementsMap.current.set(element, {
        originalTransform: style.transform === 'none' ? '' : style.transform,
      });
    }
    return elementsMap.current.get(element)!;
  };

  // Optimized mouse move handler
  const handleMouseMove = useCallback((e: MouseEvent) => {
    requestAnimationFrame(() => {
      document.querySelectorAll<HTMLElement>('[data-interactive]').forEach((element) => {
        const rect = element.getBoundingClientRect();
        const elementCenterX = rect.left + rect.width / 2;
        const elementCenterY = rect.top + rect.height / 2;

        const distanceX = e.clientX - elementCenterX;
        const distanceY = e.clientY - elementCenterY;
        const distance = Math.hypot(distanceX, distanceY);

        const radius = 40;
        const magneticStrength = 0.4;

        const { originalTransform } = getElementData(element);

        if (distance < radius) {
          const strength = (1 - distance / radius) * magneticStrength;
          const moveX = distanceX * strength;
          const moveY = distanceY * strength;

          const magneticTransform = `translate3d(${moveX}px, ${moveY}px, 0)`;
          element.style.transform = originalTransform
            ? `${originalTransform} ${magneticTransform}`
            : magneticTransform;
          element.style.transition = 'transform 0.15s ease-out';
        } else {
          // Reset to original transform
          element.style.transform = originalTransform;
          element.style.transition = 'transform 0.2s ease-out';
        }
      });
    });
  }, []);

  // Handle mouseleave on the document to reset all elements
  const handleMouseLeave = useCallback(() => {
    elementsMap.current.forEach(({ originalTransform }, element) => {
      element.style.transform = originalTransform;
      element.style.transition = 'transform 0.2s ease-out';
    });
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove, { passive: true });
    document.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseleave', handleMouseLeave);
      // Reset all elements when unmounting
      handleMouseLeave();
    };
  }, [handleMouseMove, handleMouseLeave]);
}
